<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-1" fill>
        <b-tab title="Summary" active>
          <b-card-title>
            <h3 class="app-title mdsm">Summary Triggers</h3>
          </b-card-title>
          <vue-good-table
            class="custom-data-table"
            :columns="tableColumns"
            :rows="filterSummaryTabData"
            :line-numbers="true"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.label == 'Metric'">
                <div
                  style="display: flex; justify-content: center; align-items: center; text-align: center;"
                >
                  <p
                    style="text-transform: uppercase; font-weight: bold; margin-bottom: 0;"
                  >
                    {{ props.row.metric }}
                  </p>
                  <feather-icon
                    style="margin-left: 10px;"
                    class="up-icon"
                    v-if="props.row.operator == '>'"
                    icon="ArrowUpIcon"
                    size="18"
                    color="green"
                  />
                  <feather-icon
                    style="margin-left: 10px;"
                    class="up-icon"
                    v-if="props.row.triggerType == 'Summary'"
                    icon="BarChart2Icon"
                    size="18"
                    color="blue"
                  />
                  <feather-icon
                    style="margin-left: 10px;"
                    v-if="props.row.operator == '<'"
                    icon="ArrowDownIcon"
                    size="18"
                    class="down-icon"
                    color="red"
                  />
                  <b-icon
                    class="upDownIcon"
                    icon="arrow-down-up"
                    v-if="props.row.operator == '='"
                  />
                </div>
              </span>
              <span v-if="props.column.label == 'Action'">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="
                    editItem(
                      props.row._id,
                      props.row.isPercentage,
                      props.row.operator,
                      props.row.metric,
                      props.row.isComparisonMetric,
                      props.row.zeroMetricCheckEnabled,
                      props.row.triggerType
                    )
                  "
                  ><span style="font-weight: bold;">Schedule</span></b-button
                >
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                  <!-- <b-form-select
                v-model="pageLength"
                :options="['3', '5', '7', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              /> -->
                  <!-- <span class="text-nowrap"> of {{ props.total }} entries </span> -->
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-tab>
        <b-tab title="Optimisation">
          <b-card-title>
            <h3 class="app-title mdsm">Optimisation Triggers</h3>
          </b-card-title>
          <vue-good-table
            class="custom-data-table"
            :columns="tableColumns"
            :rows="filterOptimisationTabData"
            :line-numbers="true"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.label == 'Metric'">
                <div
                  style="display: flex; justify-content: center; align-items: center; text-align: center;"
                >
                  <p
                    style="text-transform: uppercase; font-weight: bold; margin-bottom: 0;"
                  >
                    {{ props.row.metric }}
                  </p>
                  <feather-icon
                    style="margin-left: 10px;"
                    class="up-icon"
                    v-if="props.row.operator == '>'"
                    icon="ArrowUpIcon"
                    size="18"
                    color="green"
                  />
                  <feather-icon
                    style="margin-left: 10px;"
                    class="up-icon"
                    v-if="props.row.triggerType == 'Summary'"
                    icon="BarChart2Icon"
                    size="18"
                    color="blue"
                  />
                  <feather-icon
                    style="margin-left: 10px;"
                    v-if="props.row.operator == '<'"
                    icon="ArrowDownIcon"
                    size="18"
                    class="down-icon"
                    color="red"
                  />
                  <b-icon
                    class="upDownIcon"
                    icon="arrow-down-up"
                    v-if="props.row.operator == '='"
                  />
                </div>
              </span>
              <span v-if="props.column.label == 'Action'">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="
                    editItem(
                      props.row._id,
                      props.row.isPercentage,
                      props.row.operator,
                      props.row.metric,
                      props.row.isComparisonMetric,
                      props.row.zeroMetricCheckEnabled,
                      props.row.triggerType
                    )
                  "
                  ><span style="font-weight: bold;">Schedule</span></b-button
                >
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                  <!-- <b-form-select
                v-model="pageLength"
                :options="['3', '5', '7', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              /> -->
                  <!-- <span class="text-nowrap"> of {{ props.total }} entries </span> -->
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-tab>
        <b-tab disabled>
          <template #title>
            <span class="multi-line-title">
              Error<br /><span style="font-size: 10px; color: #200e6b;"
                >Coming Soon</span
              >
            </span>
          </template>
          <p>Error</p>
        </b-tab>
        <b-tab disabled>
          <template #title>
            <span class="multi-line-title">
              Others<br /><span style="font-size: 10px; color: #200e6b;"
                >Coming Soon</span
              >
            </span>
          </template>
          <p>Others</p>
        </b-tab>
      </b-tabs>
    </b-card>
    <div class="spinner-container" v-if="isScheduleTriggerPageLoading">
      <b-spinner variant="primary" large></b-spinner>
    </div>
    <transition name="slide">
      <b-card v-if="switchTable" class="animated-card">
        <div class="HistoryTable">
          <div>
            <button class="backButton" @click="goBackToPreviousPage">
              <feather-icon icon="ChevronLeftIcon" size="25" />
            </button>
          </div>
          <div>
            <b-card-title>
              <h3 class="app-title text-center">
                Trigger History for {{ this.historyClientName }}
              </h3>
            </b-card-title>
          </div>
          <div></div>
        </div>
        <p class="mt-2">{{ this.historyTriggerDescription }}</p>
        <vue-good-table
          class="custom-data-table"
          :columns="historyTable"
          :rows="historyData"
          :custom-row-key="customRowKey"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.label == 'Whatsapp'">
              <span
                v-b-tooltip.hover
                v-if="props.row.whatsapp.status === 'failed'"
                :title="props.row.email.error.slice(6)"
                class="tooltip-trigger"
              >
                {{ props.row.whatsapp.status }}
              </span>
              <span v-else :title="props.row.whatsapp.error">
                {{ props.row.whatsapp.status }}
              </span>
            </div>
            <div v-if="props.column.label == 'SMS'">
              <span
                v-b-tooltip.hover
                v-if="props.row.sms.status === 'failed'"
                :title="props.row.sms.error.slice(6)"
                class="tooltip-trigger"
              >
                {{ props.row.sms.status }}
              </span>
              <span v-else :title="props.row.sms.error">
                {{ props.row.sms.status }}
              </span>
            </div>
            <div v-if="props.column.label == 'Email'">
              <span
                v-b-tooltip.hover
                v-if="props.row.email.status === 'failed'"
                :title="props.row.email.error.slice(6)"
                class="tooltip-trigger"
              >
                {{ props.row.email.status }}
              </span>
              <span v-else :title="props.row.email.error">
                {{ props.row.email.status }}
              </span>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <!-- <b-form-select
            v-model="pageLength"
            :options="['3', '5', '7', '10']"
            class="mx-1"
            @input="
              (value) => props.perPageChanged({ currentPerPage: value })
            "
          /> -->
                <!-- <span class="text-nowrap"> of {{ props.total }} entries </span> -->
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </transition>
    <!-- Trigger-table -->
    <transition name="slide">
      <div :class="{ 'hide-table': switchHistoryTable }">
        <b-card class="animated-card">
          <div class="schedule-hearder">
            <b-card-title>
              <h3 class="app-title mdsm">Scheduled Triggers</h3>
            </b-card-title>
            <input
              type="text"
              v-model="searchTerm"
              placeholder="Search by client"
            />
          </div>
          <vue-good-table
            class="custom-data-table"
            :columns="tableColumnss"
            :rows="scheduleData"
            :line-numbers="true"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
          >
            >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.label == 'View History'">
                <button
                  class="History-Button"
                  @click="
                    getHistoryData(
                      props.row.id,
                      props.row.trigger.description,
                      props.row.client.name
                    )
                  "
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="customizeIcon"
                    size="20"
                    stroke-width="2"
                    color="#6C4E96"
                  ></feather-icon>
                </button>
              </span>
              <span v-if="props.column.label == 'Edit'">
                <button
                  class="History-Button"
                  @click="
                    getEditTrigger(
                      props.row.contact,
                      props.row.emailAddresses,
                      props.row.endDate,
                      props.row.startDate,
                      props.row.id,
                      props.row.client.name,
                      props.row.trigger._id,
                      props.row.trigger.description,
                      props.row.comparisonMetric,
                      props.row.percentage,
                      props.row.daily_budget,
                      props.row.budgetMetric,
                      props.row.avg_last_hours,
                      props.row.trigger._id,
                      props.row.triggerName
                    )
                  "
                >
                  <feather-icon
                    icon="EditIcon"
                    class="customizeIcon"
                    size="20"
                    stroke-width="2"
                    color="#6C4E96"
                  ></feather-icon>
                </button>
              </span>
              <span v-if="props.column.field == 'isActive'">
                <div
                  @change="
                    toggleIsActiveSchedule(props.row.id, props.row.isActive)
                  "
                >
                  <b-form-checkbox
                    v-model="props.row.isActive"
                    switch
                  ></b-form-checkbox>
                </div>
              </span>

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                  <!-- <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '7', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                /> -->
                  <!-- <span class="text-nowrap"> of {{ props.total }} entries </span> -->
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </div>
    </transition>
    <div>
      <transition name="modal">
        <div class="modal-mask" v-if="openScheduleModal">
          <TriggerModalAll
            :editTrigger="editTrigger"
            :editTriggerContact="editTriggerContact"
            :editTriggerClientId="editTriggerClientId"
            :editTriggerStartDate="editTriggerStartDate"
            :editTriggerEmailAddresses="editTriggerEmailAddresses"
            :editTriggerEndDate="editTriggerEndDate"
            :triggerId="triggerId"
            @closeScheduleClientPopUp="closeScheduleClientPopUp"
            :scheduleModal="this.openScheduleModal"
            :isPercentage="isPercentage"
            :isInscrease="isInscrease"
            :metric="metric"
            :isTriggerModal="isTriggerModal"
            :isComparisonMetric="isComparisonMetric"
            :zeroMetricCheckEnabled="zeroMetricCheckEnabled"
            :triggerType="triggerType"
          />
        </div>
      </transition>
      <transition>
        <div
          class="modal-dialog modal-dialog-centered"
          v-if="editScheduleModal"
        >
          <div class="modal-content modal">
            <b-modal
              v-model="editScheduleModal"
              id="main-modal-center"
              hide-footer
              hide-header
              centered
              size="small"
              no-close-on-backdrop
            >
              <b-card-text>
                <div class="header text-right">
                  <feather-icon
                    style="cursor: pointer"
                    @click="editScheduleClientPopUp"
                    class="Xicon"
                    icon="XIcon"
                  ></feather-icon>
                </div>
                <div class="modal-item">
                  <div class=" pb-1 pt-0 edit-form">
                    <h4 class="app-title lg">
                      Update Trigger for {{ editClientName }} 
                    </h4>
                  </div>
                </div>
                <!-- Trigger's Trigger Modal - this part of element will show from triggers page -->
                <div
                  v-if="
                    triggerTypeComparison ==
                      'Send Google/Amazon Ads Daily Summary Report.'
                  "
                >
                  <div style="display: flex; justify-content: space-between;">
                    <div class="radio-label">
                      <input
                        type="radio"
                        v-model="fetchByClient"
                        :value="true"
                      />
                      Clients
                    </div>
                    <div class="radio-label">
                      <input
                        type="radio"
                        v-model="fetchByClient"
                        :value="false"
                        style="margin-left: -220px;"
                      />
                      Account
                    </div>
                  </div>
                </div>
                <b-row class="pb-1">
                  <b-col cols="12" md="6" v-if="fetchByClient === true" class="mb-1"
                    >
                    <div class="w-100 update-trigger-client">
                    <label>Client</label>
                    <select
                      disabled
                      v-model="selectedClient"
                      class="w-100"
                      :class="
                        triggerTypeComparison ==
                        'Send Google/Amazon Ads Daily Summary Report.'
                          ? 'client-select-conditionally clientSelections'
                          : 'client-select'
                      "
                    >
                      <option value="">{{ this.editClientName }}</option>
                      <option
                        v-for="onetype in fetchClientsList"
                        :value="onetype._id"
                        class="form-control"
                        id="lg_type"
                        name="lg_type"
                        v-bind:key="onetype._id"
                      >
                        {{ onetype.name }}
                      </option>
                    </select>
                  </div>
                  </b-col>
                  <b-col
                    cols="12" md="6"
                    v-if="
                      triggerTypeComparison !==
                        'Send Google/Amazon Ads Daily Summary Report.'
                    "
                  >
                    <label>Percentage</label>
                    <b-input-group>
                      <!-- <b-input-group-prepend is-text>
                        <span>₹</span>
                      </b-input-group-prepend> -->
                      <b-input-group-prepend is-text>
                        <span>%</span>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="percentage"
                        id="metric-threshold"
                        placeholder="Enter Percentage"
                        type="number"
                        maxlength="3"
                      />
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row v-if="fetchByClient === false">
                  <b-col cols="12" class="mb-1"
                    ><label>Account</label>
                    <select
                      disabled
                      v-model="selectedAccount"
                      style=" background-color: #efefef;
                      color: #b8c2cc;"
                      class="client-select-conditionally"
                    >
                      <option value="Social Beat Digital Marketing"
                        >Social Beat Digital Marketing</option
                      >
                    </select>
                  </b-col>
                </b-row>
                <b-row >
                  <b-col cols="12">
                    <label>Trigger Reference</label>
                      <b-form-input class="triggerName" v-model="triggerName" id="client-name" placeholder="Enter your trigger reference." block
                        type="text"
                        />
                  </b-col>
                 </b-row>
                <div class="mt-1">
                  <b-row>
                    <b-col cols="12">
                      <label
                        >Email Id(s)<span class="validation">*</span></label
                      >
                      <b-input-group :state="state">
                        <b-form-tags
                          placeholder="Enter email id(s)"
                          v-model="emailAddresses"
                          input-id="tags-basic"
                          remove-on-delete
                          :tag-validator="tagValidator"
                          :state="state"
                          add-button-variant="primary"
                          invalid-tag-text="Please enter a valid email address."
                        />
                      </b-input-group>
                    </b-col>
                  </b-row>
                </div>
                <div
                  v-if="
                    triggerTypeComparison !==
                      'Send Google/Amazon Ads Daily Summary Report.'
                  "
                >
                  <b-row
                    class="mt-1"
                    v-if="
                      triggerTypeComparison !==
                        'Send Google/Amazon Ads Daily Summary Report.'
                    "
                  >
                    <label style="margin-left: 13px"
                      >Comparison Metric<span class="validation">*</span></label
                    >
                    <b-col cols="12">
                      <div class="info-container">
                        <span
                          v-b-tooltip.hover
                          class="info-label tooltip-trigger"
                          title="Compare with previous day's data at the same hour"
                          style="margin-left: 13px"
                        >
                          <b-icon icon="info-circle-fill" />
                        </span>

                        <span
                          v-b-tooltip.hover
                          class="info-label info-label-right tooltip-trigger"
                          title="Compare with same day's previous hour's data"
                        >
                          <b-icon icon="info-circle-fill" />
                        </span>
                      </div>
                      <div class="radio-container">
                        <div class="radio-label">
                          <input
                            type="radio"
                            v-model="comparisonMetric"
                            value="day"
                          />
                          <span style="font-size: 12px"
                            >Previous day (Same hour)
                          </span>
                        </div>
                        <div class="radio-label">
                          <input
                            type="radio"
                            v-model="comparisonMetric"
                            value="hours"
                          />
                          <span style="font-size: 12px"
                            >Previous hour (Same day)</span
                          >
                        </div>
                        <div
                          class="radio-label"
                          style="text-align: center; align-items: center;"
                        >
                          <input
                            type="radio"
                            v-model="comparisonMetric"
                            value="budget"
                          />
                          <span
                            style="font-size: 12px;  text-transform: capitalize;"
                            >Budget
                          </span>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="this.comparisonMetric == 'hours'">
                  <b-row>
                    <b-col cols="12">
                      <label>Select Hour </label>
                      <div class="input-hour-container">
                        <input
                          type="number"
                          min="1"
                          max="24"
                          class="client"
                          v-model="avg_last_hours"
                          @input="checkHourLimits"
                          maxlength="2"
                        />
                        <div class="buttons-group">
                          <button
                            class="up"
                            @click="incrementHour"
                            :disabled="avg_last_hours >= 24"
                          >
                            ▲
                          </button>
                          <button
                            class="down"
                            @click="decrementHour"
                            :disabled="avg_last_hours <= 1"
                          >
                            ▼
                          </button>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-row
                  v-if="
                    triggerTypeComparison !==
                      'Send Google/Amazon Ads Daily Summary Report.'
                  "
                  class="mt-1"
                >
                  <b-col cols="12" v-if="this.comparisonMetric == 'budget'">
                   
                    <label style="text-transform: capitalize;">
                      <!-- <div class="update-trigger-client"> -->
                      <span
                        style="font-size: 12px;  text-transform: capitalize;"
                        >Budget per</span
                      >
                      <!-- <span
                        v-else
                        style="font-size: 12px;  text-transform: capitalize;"
                        >Budget Per</span> -->
                      <b-dropdown
                        size="sm"
                        disabled
                        class="Budget"
                        :text="this.budgetMetric"
                        style="margin-left: 5px; margin-bottom: 5px; width: 95px; height: 37px;"
                        variant="outline-primary"
                        id="dropdown-dropright"
                        dropright
                      >
                        <b-dropdown-item
                          v-for="(item, index) in dropdownBudgetItems"
                          :key="index"
                          @click="selectBudgetItem(item)"
                        >
                          {{ item }}
                        </b-dropdown-item>
                      </b-dropdown>
                    <!-- </div> -->
                    </label>
                    <input
                      type="number"
                      
                      class="
                        client-budget 
                      "
                      v-model="daily_budget"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="mt-1">
                    <label>SMS/WhatsApp<span class="validation">*</span></label>
                    <b-input-group
                      :class="{
                        valid: isValidPhoneNumber,
                        invalid: !isValidPhoneNumber,
                      }"
                    >
                      <b-input-group-prepend is-text>
                        <span>+91</span>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="contact"
                        id="client-name"
                        placeholder="Enter your contact number."
                        type="number"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="10"
                        @keyup="validatePhoneNumber"
                      />
                    </b-input-group>
                    <div class="invalid-warning" v-if="!isValidPhoneNumber">
                      Please enter a valid 10-digit mobile number.
                    </div>
                  </b-col>
                </b-row>
              </b-card-text>
              <b-row class="mt-1">
                <div class="date-value">
                  <div class="date-container">
                    <b-col cols="12" md="3">
                      <div class="update-trigger-client">

                      <label
                        >Start Date<span class="validation">*</span></label
                      >
                      <input
                        v-model="startDate"
                        placeholder="Choose a start-date"
                        type="date"
                        :min="myDate"
                        style="width:100%"
                      />
                      </div>
                    </b-col>
                    <b-col cols="12" md="3">
                      <div class="update-trigger-client">
                      <label>End Date<span class="validation">*</span></label>
                      <input
                        v-model="endDate"
                        placeholder="Choose a start-date"
                        type="date"
                        :min="myDate"
                        style="width:100%"
                      />
                    </div>
                    </b-col>
                  </div>
                  <p v-if="isInvalid" class="text-danger ml-1 mt-0.5 text-xs">
                    End date must be greater than or equal to start date.
                  </p>
                </div>
              </b-row>
              <div class="button-group mb-2">
                <b-row>
                  <b-col cols="12">
                    <b-button
                      class="py-1 px-10 mt-2 btn-size"
                      block
                      variant="primary"
                      id="show-btn"
                      @click="editScheduledTrigger()"
                    >
                      Update Trigger</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </b-modal>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import TriggerModalAll from "@/components/client/TriggerModalAll.vue";
import axios from "axios";
import vSelect from "vue-select";
import VuePhoneNumberInput from "vue-phone-number-input";
import validator from "validator";
import Cookies from "js-cookie";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardTitle,
  BButton,
  BFormCheckbox,
  BTooltip,
  BSpinner,
  BIcon,
  BTab,
  BTabs,
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormTags,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import { getUserData, getUserToken, getUserEmail } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
export default {
  components: {
    vSelect,
    VuePhoneNumberInput,
    ToastificationContent,
    BButton,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardTitle,
    BFormCheckbox,
    BTooltip,
    BSpinner,
    BIcon,
    TriggerModalAll,
    BTab,
    BTabs,
    BModal,
    BRow,
    BCol,
    BCardText,
    BFormTags,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  props: {
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "date",
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      triggerName:"",
      avg_last_hours: 1,
      daily_budget: 0,
      percentage: 0,
      comparisonMetric: "",
      triggerTypeComparison: "",
      scheduleTriggerId: "",
      triggerEditID: "",
      fetchByClient: true,
      contact: "",
      editTrigger: false,
      editTriggerContact: "",
      editTriggerClientId: "",
      customRowKey: "customRowType",
      editTriggerEmailAddresses: [],
      editTriggerStartDate: "",
      editTriggerEndDate: "",
      openScheduleModal: false,
      editScheduleModal: false,
      searchTerm: "",
      triggerId: "",
      editing_user_id: 0,
      showModal: false,
      triggerData: [],
      scheduleData: [],
      pageLength: 5,
      searchTerm: "",
      isActive: false,
      historyData: [],
      switchTable: false,
      switchHistoryTable: false,
      historyClientName: "",
      historyTriggerDescription: "",
      isScheduleTriggerPageLoading: false,
      isPercentage: false,
      isInscrease: "",
      isDescrease: "",
      metric: "",
      isTriggerModal: false,
      isComparisonMetric: false,
      zeroMetricCheckEnabled: false,
      triggerType: "",
      filteredSummaryArray: [],
      filteredOpimizationsArray: [],
      editClientName: "",
      fetchClientsList: [],
      selectedClient: "",
      selectedAccount: "Social Beat Digital Marketing",
      selectedChannels: [],
      allChannels: ["google-ads", "amazon", "meta"],
      emailAddresses: [],
      contact: "",
      isValidPhoneNumber: true,
      isValidPhoneEmail: true,
      startDate: "",
      endDate: "",
      emailAddressesSchedule: "",
      dropdownBudgetItems: ["hour", "day", "month", "lifetime"],
      selectedBudgetItem: "hour",
      myDate: new Date().toISOString().slice(0, 10),
      todayDateCompare: new Date().toISOString().split('T')[0],
    };
  },
  mounted() {
    this.fetchClients();
    this.minDate = new Date().toISOString().split("T")[0];
  },
  methods: {
    addActivityLog() {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/logs/`, {
              "description": "Triggers Opened",
              "action": "view"
          }, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        })
        .then(response => {
        })
        .catch(error => {
        });
    },
    selectBudgetItem(item) {
      this.selectedBudgetItem = item;
    },
    incrementHour() {
      this.avg_last_hours++;
    },
    decrementHour() {
      if (this.avg_last_hours > 1) {
        this.avg_last_hours--;
      }
    },
    handleWarning() {
      this.showLastModal = true;
      this.warningMessage = false;
      this.popupMessage = true;
    },
    tagValidator(tag) {
      // Individual tag validator function
      return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 6;
    },
    validatePhoneNumber() {
      const validationRegex = /^\d{10}$/;
      if (this.contact.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else if (this.contact == "") {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    validateEmail() {
      const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      4;
      if (this.isTriggerModal == true) {
        if (this.emailAddresses.match(validateEmail)) {
          this.isValidPhoneEmail = true;
        } else if (this.emailAddresses == "") {
          this.isValidPhoneEmail = true;
        } else {
          this.isValidPhoneEmail = false;
        }
      } else {
        if (this.emailAddress.match(validateEmail)) {
          this.isValidPhoneEmail = true;
        } else if (this.emailAddress == "") {
          this.isValidPhoneEmail = true;
        } else {
          this.isValidPhoneEmail = false;
        }
      }
    },
    tagValidator(tag) {
      return validator.isEmail(tag);
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    fetchClients() {
      // this.isBusy = true;
      const storedDate = Cookies.get('lastVisitedTriggersDate');
      if (storedDate === this.todayDateCompare) {
        console.log("");
      } else {
        this.addActivityLog();
        Cookies.set('lastVisitedTriggersDate', this.todayDateCompare, { expires: 1 }); 
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/get`,
          {
            limit: 50,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.fetchClientsList = response.data.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleIsActiveSchedule(id, isActive) {
      axios
        .patch(
          `${process.env.VUE_APP_SERVER_URL}/api/triggers/scheduled/${id}`,
          { isActive: isActive },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Updated",
            "The scheduled trigger's status has been updated.",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    formatDate(value) {
      const date = new Date(value);
      const formattedDate = moment(date, "DD-MM-YYYY").format("DD-M-YYYY");
      const time = moment(value.substring(11, 16), "HH:mm").format("hh:mm A");
      return `${formattedDate} ${time}`;
    },
    getTriggerData() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/triggers`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            "Content-type": "application/json",
          },
        })
        .then((response) => {
          this.triggerData = response.data.triggers;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    goBackToPreviousPage() {
      this.isScheduleTriggerPageLoading = true;
      setTimeout(() => {
        this.isScheduleTriggerPageLoading = false;
        this.switchHistoryTable = false;
      }, 500);
      this.switchTable = false;
    },
    getEditTrigger(
      contactNum,
      emailAddresses,
      endDate,
      startDate,
      id,
      name,
      triggerId,
      triggerType,
      comparisonMetric,
      percentage,
      daily_budget,
      budgetMetric,
      avg_last_hours,
      triggerTypeForComaparisonId,
      triggerName
    ) {
      this.editScheduleModal = true;
      this.editClientName = name;
      this.editTrigger = true;
      this.scheduleTriggerId = id;
      this.triggerEditID = triggerId;
      this.triggerTypeComparison = triggerType;
      this.comparisonMetric = comparisonMetric;
      this.budgetMetric = budgetMetric;
      this.percentage = percentage;
      this.daily_budget = daily_budget;
      this.avg_last_hours = avg_last_hours;
      this.triggerTypeForComaparisonId = triggerTypeForComaparisonId;
      this.triggerName = triggerName
      this.endDate = endDate
        .split(" ")[0]
        .split("-")
        .reverse()
        .join("-");
      this.startDate = startDate
        .split(" ")[0]
        .split("-")
        .reverse()
        .join("-");
        if (contactNum.startsWith("+91")) {
    this.contact = contactNum.substring(3);
}else{
  this.contact = contactNum
}
      this.emailAddresses = emailAddresses.split(",");
      let contactNew = contactNum.replace(/^\+\d{1,}/, "");
      console.log(
        contactNum.substring(3),
        contactNew,
        emailAddresses,
        endDate,
        startDate,
        id,
        name,
        triggerId
      );
      this.fetchClients();
    },
    getHistoryData(id, description, name) {
      window.scrollTo(0, document.body.scrollHeight);
      this.isScheduleTriggerPageLoading = true;
      setTimeout(() => {
        this.isScheduleTriggerPageLoading = false;
        this.switchTable = true;
      }, 500);

      this.switchHistoryTable = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/triggers/history/${id}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            "Content-type": "application/json",
          },
        })
        .then((response) => {
          this.historyData = response.data.triggers;
          this.switchHistoryTable = true;
          this.historyClientName = name;
          this.historyTriggerDescription = description;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    getScheduleData() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/triggers/scheduled`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            "Content-type": "application/json",
          },
        })
        .then((response) => {
          this.scheduleData = response.data.triggers;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    editItem(
      id,
      isPercentage,
      isInscrease,
      metric,
      isComparisonMetric,
      zeroMetricCheckEnabled,
      triggerType
    ) {
      this.isPercentage = isPercentage;
      this.zeroMetricCheckEnabled = zeroMetricCheckEnabled;
      this.isInscrease = isInscrease;
      this.triggerId = id;
      this.openScheduleModal = true;
      this.metric = metric;
      this.isTriggerModal = true;
      this.editTrigger = false;
      this.isComparisonMetric = isComparisonMetric;
      this.triggerType = triggerType;
    },
    checkHourLimits() {
      if (this.avg_last_hours > 24) {
        this.avg_last_hours = 24;
      } else if (this.avg_last_hours < 1) {
        this.avg_last_hours = 1;
      }
    },
    editScheduledTrigger() {
      const id = this.scheduleTriggerId;
      const emailAddresses = this.emailAddresses.join(",");
      const contact = this.contact;
      const startDate = this.startDate;
      const endDate = this.endDate;
      const daily_budget = parseInt(this.daily_budget);
      const percentage = this.percentage;
      const budgetMetric = this.selectedBudgetItem;
      const comparisonMetric = this.comparisonMetric;
      const avg_last_hours = parseInt(this.avg_last_hours);
      const triggerTypeForComaparisonId = this.triggerTypeForComaparisonId;
      const triggerName = this.triggerName
      axios
        .patch(
          `${process.env.VUE_APP_SERVER_URL}/api/triggers/edit-trigger`,
          {
            id,
            emailAddresses,
            contact,
            endDate,
            startDate,
            daily_budget,
            percentage,
            budgetMetric,
            comparisonMetric,
            avg_last_hours,
            triggerTypeForComaparisonId,
            triggerName
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast("Saved", "Trigger update successfully", "success");
          this.editScheduleModal = false;
          this.getScheduleData();
        })
        .catch((error) => {
          this.editScheduleModal = false;
          console.log("error");
        });
    },
    editScheduleClientPopUp() {
      this.editTrigger = false;
    },
    closeScheduleClientPopUp() {
      this.getScheduleData();
      this.openScheduleModal = false;
    },
    editScheduleClientPopUp() {
      this.editScheduleModal = false;
    },
  },
  computed: {
    isBtnDisabled() {
      return (
        this.selectedClient.length === 0 ||
        // this.selectedChannels.length === 0 ||
        this.startDate === "" ||
        this.endDate === ""
        // this.emailAddresses.length === 0 ||
        // this.contact.length === 0 ||
        // // this.daily_budget.length === 0 ||
        // this.startDate > this.endDate
      );
    },
    isBtnDisabledWithoutDates() {
      return (
        this.selectedClient.length === 0 ||
        this.contact.length === 0 ||
        this.emailAddresses === ""
      );
    },
    isBtnDisabledConditionalWithoutDates() {
      return this.contact.length === 0 || this.emailAddresses === "";
    },
    isInvalid() {
      if (this.startDate && this.endDate) {
        return this.startDate > this.endDate;
      }
      return false;
    },
    state() {
      return this.dirty
        ? this.emailAddresses.length > 2 && this.emailAddresses.length < 9
        : null;
    },
    filterSummaryTabData() {
      const filtered = this.triggerData.filter(
        (item) => item.triggerType == "Summary"
      );
      return filtered;
    },
    filterOptimisationTabData() {
      const filtered = this.triggerData.filter(
        (item) => item.triggerType !== "Summary"
      );
      return filtered;
    },
    historyTable() {
      let columns = [
        {
          label: "Whatsapp",
          field: "-",
        },
        {
          label: "SMS",
          field: "-",
        },
        {
          label: "Email",
          field: "-",
        },
        {
          label: "Trigger Time",
          field: "createdAt",
          formatFn: this.formatDate,
        },
      ];
      return columns;
    },
    tableColumns() {
      let columns = [
        {
          label: "Metric",
          field: "-",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Description",
          field: "description",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Action",
          field: "-",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ];
      return columns;
    },
    tableColumnss() {
      let columns = [
        {
          label: "Client",
          field: "client.name",
        },
        {
          label: "Trigger Reference",
          field: "triggerName",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Trigger Description",
          field: "trigger.description",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Start Date",
          field: "startDate",
        },
        {
          label: "End Date",
          field: "endDate",
        },
        {
          label: "View History",
          field: "-",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Edit",
          field: "-",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Status",
          field: "isActive",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ];
      return columns;
    },
  },
  mounted() {
    this.getTriggerData();
    this.getScheduleData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";




@media only screen and (max-width: 700px) {
  // .radio-container{
  //   flex-direction: column;
  // }
  .button-group{
    width: 100%;
  }

  .btn-size{
    width: 100% !important;
  }
  .date-value{
    width: 100%
    ;
  }
  .client-budget {
    width: 100% !important;

  }
  .info-container{
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }
  .date-container{
    flex-direction: column;
    gap: 1rem !important;
  }

  .update-trigger-client{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media only screen and (min-width: 700px) {
  .client-budget {
    width: 282px !important;
  }
}
.campaign-name-field {
  max-width: 200px;
}
.History-Button:disabled {
  opacity: 0.2;
  color: #ccc;
  cursor: not-allowed;
}
.Budget:disabled{
  background-color: #e3e2e2 !important;
  color: #b8c2cc !important;
}
.schedule-hearder {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between; 
}
input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 205px;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.History-Button {
  justify-content: center;
  align-items: center;
  border: none;
  background-color: white;
}
.HistoryTable {
  display: flex;
  justify-content: space-between;
}
.modal-mask {
  overflow: hidden !important;
}
.disable-scroll {
  overflow: hidden;
}
.backButton {
  background: none;
  border: none;
}
.backButton:hover {
  border-radius: 10px;
  background-color: rgb(249, 242, 242);
}
.customizeIcon {
  width: 25px;
  align-items: center;
  margin-top: 10px;
}
/* CSS */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.animated-card {
  animation: slide-in 0.3s;
}
.spinner-container {
  position: fixed;
  top: 80%;
  left: 60%;
  transform: translate(-50%, -50%);
}
.tooltip-trigger {
  position: relative;
  cursor: pointer;
}
.hide-table {
  display: none;
}
.upDownIcon {
  margin-left: 10px;
  margin-top: 2px;
  color: blue;
  font-size: 17px;
}
table.vgt-table td {
  vertical-align: middle !important;
  color: #6e6b7b;
}

.radio-label input[type="radio"] {
  transform: scale(0.6);
  margin: 0;
  padding: 0;
  width: 30px;
}
.radio-label {
  display: flex;
  align-items: center;
}

.radio-container {
  display: flex;
}
.client-select-conditionally {
  border-right: 10px solid transparent;
  outline: 1px solid #d8d6de;
  width: 460px !important;
  height: 38px;
  background-color: white;
  border: 0px;
  border-radius: 5px;
  padding-left: 8px;
  color: rgb(163, 153, 153);
}
.client-select-conditionally:disabled {
  background-color: #c1bfbf;
  color: #b8c2cc;
}
.client-select:disabled {
  background-color: #e3e2e2;
  color: #b8c2cc;
}
.client-select {
  border-right: 10px solid transparent;
  outline: 1px solid #d8d6de;
  height: 38px;
  background-color: white;
  border: 0px;
  border-radius: 5px;
  padding-left: 8px;
  color: rgb(163, 153, 153);
}
.invalid-warning {
  margin: 2px auto;
  color: red;
  font-size: 12px;
  text-align: left;
}


.btn-size {
  width: 460px;
}

.date-container {
  display: flex;
  gap: 6.8rem;
}

.emailTag {
  padding: 0px 10px;
}

.checkbox-container {
  display: flex;
  gap: 5px;
}

.clientSelections:disabled {
  background-color: #efefef;
  color: #b8c2cc;
}
.info-container {
  display: flex;
  margin-left: 12rem;
  margin-bottom: -14px;
}

.info-label-right {
  margin-left: 12rem;
  margin-bottom: -14px;
}
.input-hour-container {
  position: relative;
  display: inline-block;
}
.buttons-group {
  position: absolute;
  top: 25%;
  right: 15px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
}
.client {
  width: 460px !important;
  height: 38px;
  border: 0px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  border-radius: 5px;
  padding-left: 8px;
  color: rgb(163, 153, 153);
}
.client-budget {
  height: 38px;
  border: 0px;
  margin-left: 5px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  border-radius: 5px;
  padding-left: 8px;
  color: rgb(163, 153, 153);
}
.buttons-group > button {
  width: 10px;
  height: 15px;
  background: none;
  border: none;
  color: #432f94;
}
.validation {
  color: red;
  margin-left: 2px;
  border-radius: 5px;
}
</style>
